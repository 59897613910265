import { InputLabel, TextField } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { TextInputWrapper } from './TextInput.styled';
import { backgroundColor } from '../AdminScreen.helper';

const TextInput = (props) => {
    const { name, title, value, required, disabled,
        handleInputChange, errorMsg, maxLength = 255, isMultiline, width, type, handleBlur } = props;

    const bgColor = backgroundColor(value, required);
    const multilineWidth = width || '250px';
    const multiLineStyles = !isMultiline
        ? { paddingBottom: '12px', width: '250px' }
        : { width: multilineWidth };
    const exceptionList = ['e', 'E', '+', '-', '.'];
    const handleKeyDown = (e) => {
        if (type === 'number') {
            exceptionList.includes(e.key) && e.preventDefault();
        }
    };

    return (
        <TextInputWrapper multiline={isMultiline}>
            <div style={{ display: 'flex' }}>
                <InputLabel style={{ fontSize: '12px', color: 'black', paddingLeft: '3px', paddingBottom: '0', fontWeight: '600' }}>
                    {title}
                </InputLabel>
                {errorMsg && <span style={{ fontSize: '9px', color: 'rgb(255, 0, 0)', fontWeight: 'bold', right: '10%' }}>{errorMsg}</span>}
            </div>
            {!disabled
                ? (
                    <TextField
                        autoComplete="off"
                        fullWidth
                        inputProps={{ style: { fontSize: '11px', backgroundColor: bgColor, paddingLeft: '8px', paddingRight: '8px' }, maxLength }}
                        margin="dense"
                        name={name}
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        style={multiLineStyles}
                        onKeyDown={handleKeyDown}
                        type={type || 'string'}
                        value={value}
                        variant="outlined"
                        error={Boolean(errorMsg)}
                        multiline={isMultiline}
                    />
                )
                : (
                    <InputLabel style={{ fontSize: '11px', color: 'black', paddingLeft: '5px', marginTop: '9px', width: '250px' }}>
                        {value}
                    </InputLabel>
                )}

        </TextInputWrapper>
    );
};

export default TextInput;

TextInput.propTypes = {
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
    handleBlur: PropTypes.func,
    handleInputChange: PropTypes.func,
    isMultiline: PropTypes.bool,
    maxLength: PropTypes.string,
    name: PropTypes.string,
    required: PropTypes.bool,
    title: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.any,
    width: PropTypes.string
};
