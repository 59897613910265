import { SetpointSectionDataGaugeReading } from './Data/C11/C11.data';
import { SetpointSectionDataTestModuleReading } from './Data/P61/P61.data';
import { SetpointSectionDataTransmitterReading } from './Data/P711/P711.data';
import { SetpointSectionDataP720P721 } from './Data/P720/P720.data';
import { SetpointSectionDataAnalyzerReading } from './Data/P8111/P8111.data';
import { SetpointSectionDataP65P77P831 } from './Data/P65/P65.data';
import { SetpointSectionDataP724 } from './Data/P724/P724.data';
import { SetpointSectionDataTestDeviceReading } from './Data/P6022/P6022.data';
import { SetpointSectionDataS72 } from './Data/S72/S72.data';

export const selectSetpointSectionData = (selectedTag, formName, formData) => {
    let filterViewData = { rows: [] };
    if (['C1-1', 'C1-3', 'C1-4', 'P8-5.1'].includes(formName)) {
        filterViewData = SetpointSectionDataGaugeReading(selectedTag, formData);
    } else if (['P6-1', 'P6-2', 'P6-3', 'P6-4', 'P7-2',
        'P7-3', 'P7-4', 'P7-4.1', 'P8-1.1', 'P8-2.1', 'P8-7.1'].includes(formName)) {
        filterViewData = SetpointSectionDataTestModuleReading(selectedTag, formData);
    } else if (['P7-11', 'P6-17', 'P7-8', 'P7-12', 'P7-28', 'P6-8', 'P7-10', 'P8-13.1', 'P7-35', 'P6-28'].includes(formName)) {
        filterViewData = SetpointSectionDataTransmitterReading(selectedTag, formName, formData);
    } else if (['P7-20', 'P7-21', 'P6-26'].includes(formName)) {
        filterViewData = SetpointSectionDataP720P721(selectedTag, formData);
    } else if (['P8-11.1', 'P6-9', 'P8-6.1'].includes(formName)) {
        filterViewData = SetpointSectionDataAnalyzerReading(selectedTag, formData, formName);
    } else if (['P6-5', 'P7-7', 'P8-3.1'].includes(formName)) {
        filterViewData = SetpointSectionDataP65P77P831(selectedTag, formName, formData);
    } else if (['P7-24'].includes(formName)) {
        filterViewData = SetpointSectionDataP724(selectedTag, formData);
    } else if (['P6-22', 'P7-22', 'P7-15', 'P7-14', 'P7-18', 'P6-12', 'P7-29', 'P8-8.1', 'P8-12.1', 'P7-30', 'P6-27', 'P6-31'].includes(formName)) {
        filterViewData = SetpointSectionDataTestDeviceReading(selectedTag, formName, formData);
    } else if (['S7-2', 'S7-3', 'S7-4'].includes(formName)) {
        filterViewData = SetpointSectionDataS72(selectedTag, formName, formData);
    }
    return filterViewData;
};
