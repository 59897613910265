const paddingValNote = '20px 0 20px 0';

export const NoteSectionDataF84Key = (selectedTag, formName, formData) => {
    let noteLabel;
    let inPutWidth;
    if (formName === 'P7-1' || formName === 'P7-2' || formName === 'P7-3' || formName === 'P7-5') {
        noteLabel = '7.4rem';
        inPutWidth = '98%';
    } else if (formName === 'P8-1') {
        noteLabel = '4.4rem';
        inPutWidth = '100%';
    } else if (formName === 'P6-2' || formName === 'P6-2.2' || formName === 'P8-2.1' || formName === 'P8-1.1'
        || formName === 'P8-5.1' || formName === 'C1-1' || formName === 'C1-3' || formName === 'C1-3.2'
        || formName === 'C1-4' || formName === 'P8-7.1' || formName === 'P6-4'
        || formName === 'P6-4.2') {
        noteLabel = '7.5rem';
        inPutWidth = '89%';
    } else if (['P6-1', 'P6-1.2', 'P8-6.1', 'P6-9', 'P7-24',
        'P8-11.1', 'P7-20', 'P7-21', 'P7-11', 'P6-22', 'P7-22', 'P7-15', 'P7-14',
        'P7-18', 'P6-12', 'P7-29', 'P8-8.1', 'P8-12.1', 'P7-30', 'P8-13.1', 'P6-26', 'P6-27',
        'P6-25', 'P6-31', 'P6-30', 'P7-35', 'P6-28'].includes(formName)) {
        noteLabel = '9rem';
        inPutWidth = '97%';
    } else {
        noteLabel = '3.9rem';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F84Note',
                    label: 'Note',
                    value: formData.F84Note,
                    isMultiline: true,
                    width: noteLabel,
                    inputWidth: inPutWidth,
                    padding: paddingValNote,
                    marginRight: '1rem',
                    direction: 'rtl'
                }]
            }
        ]
    };
};

export const NoteSectionDataF15Key = (selectedTag, formName, formData) => {
    let noteLabel;
    let inPutWidth;
    if (formName === 'C1-2' || formName === 'P7-7' || formName === 'P6-5' || formName === 'P6-5.2' || formName === 'P6-3'
        || formName === 'P6-3.2' || formName === 'P6-6' || formName === 'P8-3.1' || formName === 'M6-8'
        || formName === 'M6-11' || formName === 'M6-14' || formName === 'M6-15' || formName === 'P7-18'
        || formName === 'M6-16' || formName === 'P8-14.1' || formName === 'P8-17.1' || formName === 'P6-29' || formName === 'P6-27') {
        noteLabel = '7.5rem';
        inPutWidth = '89%';
    } else if (formName === 'M6-13' || formName === 'M6-7' || formName === 'P6-10' || formName === 'P7-6' || formName === 'P7-27'
        || formName === 'M6-9' || formName === 'M6-6' || formName === 'P7-16' || formName === 'P6-13' || formName === 'S7-1') {
        noteLabel = '7.4rem';
        inPutWidth = '88%';
    } else if (['P6-8'].includes(formName)) {
        noteLabel = '7.5rem';
        inPutWidth = '90%';
    } else if (['M6-12', 'P8-9.1', 'P7-19'].includes(formName)) {
        noteLabel = '7.4rem';
        inPutWidth = '98%';
    } else if (formName === 'P6-7' || formName === 'P6-18' || formName === 'P8-4.1' || formName === 'P8-10.1' || formName === 'P7-13' || formName === 'P6-16') {
        noteLabel = '11.8rem';
        inPutWidth = '100%';
    } else if (formName === 'M6-10' || formName === 'M6-3' || formName === 'M6-4'
        || formName === 'M6-5' || formName === 'M3-1' || formName === 'P6-11'
        || formName === 'P6-20' || formName === 'P7-23' || formName === 'P7-17'
        || formName === 'P7-33') {
        noteLabel = '7.4rem';
        inPutWidth = '88.4%';
    } else if (formName === 'P7-9') {
        noteLabel = '11.2rem';
        inPutWidth = '100%';
    } else if (['P7-12', 'P7-10', 'P7-8', 'P6-17', 'P6-14.2', 'P7-25', 'P6-14',
        'P7-26', 'P7-28', 'P7-31', 'P6-15', 'P7-32', 'P6-23.2', 'S7-2', 'S7-3', 'S7-4', 'M4-2'].includes(formName)) {
        noteLabel = '10.2rem';
        inPutWidth = '100%';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F15Note',
                    label: 'Note',
                    value: formData.F15Note,
                    isMultiline: true,
                    width: noteLabel,
                    inputWidth: inPutWidth,
                    padding: paddingValNote,
                    marginRight: '1rem',
                    direction: 'rtl'
                }]
            }
        ]
    };
};
export const NoteSectionDataP74 = (selectedTag, formName, formData) => {
    let noteLabel;
    let inPutWidth;

    if (formName === 'P7-4' || formName === 'P7-4.1' || formName === 'M7-1') {
        noteLabel = '7.4rem';
        inPutWidth = '98%';
    } else if (formName === 'M6-1') {
        noteLabel = '7.4rem';
        inPutWidth = '88.4%';
    }
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'F84Note',
                    label: 'Note',
                    value: formData.F84Note,
                    isMultiline: true,
                    width: noteLabel,
                    inputWidth: inPutWidth,
                    padding: paddingValNote,
                    marginRight: '1rem',
                    direction: 'rtl'
                }]
            }
        ]
    };
};

export const selectNoteTemplateForForm = (selectedTag, formName, formData) => {
    let filterViewData = NoteSectionDataF84Key(selectedTag, formName, formData);
    if (['P7-6', 'P6-3', 'P6-3.2', 'P6-6', 'P6-10', 'P7-27', 'M3-1', 'M6-13',
        'M6-3', 'P6-11', 'P6-20', 'P7-33', 'P7-17', 'M6-4', 'M6-10', 'M6-11', 'M6-14',
        'M6-15', 'M6-16', 'M6-5', 'M6-7', 'M6-6', 'P7-16', 'M6-8', 'M6-9', 'M6-12', 'P6-7',
        'P8-4.1', 'P8-10.1', 'P7-13', 'P7-9', 'P7-7', 'P7-19', 'P6-5', 'P6-5.2', 'P7-12',
        'P7-10', 'P7-8', 'P7-23', 'P6-17', 'P8-3.1', 'P6-14.2', 'P6-14', 'P6-23.2', 'P8-17.1', 'P7-25',
        'P7-26', 'P7-28', 'P7-31', 'P6-15', 'P6-16', 'P7-32', 'P8-9.1',
        'P8-14.1', 'P6-13', 'P6-18', 'C1-2', 'P6-8', 'S7-1', 'S7-2', 'S7-3', 'S7-4', 'M4-2', 'P6-29'].includes(formName)) {
        filterViewData = NoteSectionDataF15Key(selectedTag, formName, formData);
    } else if (['P7-4', 'M6-1', 'P7-4.1', 'M7-1'].includes(formName)) {
        filterViewData = NoteSectionDataP74(selectedTag, formName, formData);
    }
    return filterViewData;
};
