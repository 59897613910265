import { paddingLabel } from '../../../HMISection/Data/C11/C11.data';
import { labelWidth } from '../../../HMISection/Data/P710/P710.data';

export const FailureModeSectionDataP628 = (formData) => ({
    rows: [
        {
            key: 1,
            fields: [{
                key: 'F1045EchoCurveAnalysisLabel',
                label: 'Echo Curve Analysis',
                xs: 4,
                isLabel: true,
                labelWidth,
                fontWeight: '600',
                fontSize: '9pt',
                textAlign: 'left',
                padding: paddingLabel,
                marginTop: '15px',
            },
            {
                key: 'F1045EchoCurveAnalysis',
                value: formData.F1045EchoCurveAnalysis,
                isDropdown: true,
                placeholder: 'Select Analysis',
                options: [
                    { name: 'Select Analysis', value: '' },
                    { name: 'Satisfactory', value: 'Satisfactory' },
                    { name: 'Anomaly', value: 'Anomaly' }],
                width: '100%',
                labelWidth: '0%',
                marginTop: '15px',
                xs: 2,
                inputTextAlign: 'center',
                textAlignLabel: 'end',
            },
            {
                key: 'Nodata',
                isLabel: true,
                xs: 6,
            }]
        },
        {
            key: 2,
            fields: [{
                key: 'F1046ObservationsEchoCurve',
                label: 'Observations from Echo Curve Analysis',
                value: formData.F1046ObservationsEchoCurve,
                isMultiline: true,
                isRequired: formData.F1045EchoCurveAnalysis === 'Anomaly',
                xs: 12,
                width: '50%',
                inputWidth: '50%',
                marginToalignItemsp: '15px',
                alignItems: 'inherit'
            }]
        },
        {
            key: 3,
            fields: [{
                key: 'F1047MeasuredDielectricConstantLabel',
                label: 'Measured Dielectric Constant (DC) of Fluid',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                marginTop: '20px',
            },
            {
                key: 'F1047MeasuredDielectricConstant',
                value: formData.F1047MeasuredDielectricConstant,
                xs: 2,
                labelWidth: '0%',
                textAlign: 'center',
                width: '100%',
                height: '1.2rem',
                marginTop: '20px',
                type: 'number',
                maxLength: '10',
            },
            {
                key: 'F1048ConfiguredDielectricConstantLabel',
                label: 'Configured Dielectric Constant (DC) of Fluid',
                xs: 4,
                isLabel: true,
                fontWeight: '600',
                labelWidth,
                fontSize: '9pt',
                textAlign: 'right',
                marginTop: '20px',
            },
            {
                key: 'F1048ConfiguredDielectricConstant',
                value: formData.F1048ConfiguredDielectricConstant,
                xs: 2,
                width: '100%',
                labelWidth: '0%',
                textAlign: 'center',
                marginTop: '20px',
                height: '1.2rem',
                type: 'number',
                maxLength: '10',
                marginRight: '0'
            }
            ]
        }
    ]
});
