import { conditionalValueSelector } from 'helpers/Validation';

const toleranceInInstrumentEuComment = (selectedTag, formData) => formData?.F742ToleranceInInstrumentEuComment ?? selectedTag?.ToleranceInInstrumentEuComment;
export const ToleranceInstrumentEUComment = (selectedTag, formName, formData) => {
    const formWithLeftPadding1 = ['P6-7', 'P6-16', 'P7-9', 'P7-13', 'P8-4.1', 'P8-10.1', 'P6-18'];
    const formWithXS3 = ['P6-9', 'P7-24', 'P8-6.1', 'P7-20', 'P6-26'];
    const formWithXS6 = ['P6-14.2', 'P7-31', 'P6-23.2', 'P6-14'];
    const xsVal = () => {
        if (formWithXS3.indexOf(formName) !== -1) {
            return 3;
        }
        if (formWithXS6.indexOf(formName) !== -1) {
            return 6;
        }
        return 2;
    };
    const paddingVal = () => {
        if (formWithLeftPadding1.indexOf(formName) !== -1) {
            return '5px 0 0 19px';
        }
        return '5px 0 0 0';
    };
    const labelWidthVal = () => {
        if (formWithXS6.includes(formName)) {
            return '99%';
        }
        return '100%';
    };
    return {
        rows: [
            toleranceInInstrumentEuComment(selectedTag, formData) ? {
                key: 2,
                fields: [
                    {
                        key: 'F742ToleranceInInstrumentEUCommentLabel',
                        label: 'Tolerance Comment',
                        xs: xsVal(),
                        textAlign: conditionalValueSelector(formWithXS6.includes(formName), 'right', 'left'),
                        fontStyle: 'italic',
                        padding: '5px 0 0 0',
                        isLabel: true,
                        labelWidth: labelWidthVal(),
                        fontSize: '9pt',
                        fontWeight: '600',
                    },
                    {
                        key: 'F742ToleranceInInstrumentEuComment',
                        value: selectedTag?.DateTested ? formData?.F742ToleranceInInstrumentEuComment
                            : toleranceInInstrumentEuComment(selectedTag, formData),
                        textAlign: 'center',
                        fontStyle: 'italic',
                        isInfo: true,
                        wordBreak: false,
                        width: '100%',
                        height: '1.2rem',
                        padding: paddingVal(),
                        labelWidth: '0',
                        noWrap: true
                    }]
            } : {
                key: 2,
                fields: []
            },
        ]
    };
};

export const selectToleranceInstrumentEUComment = (selectedTag, formName, formData) => {
    const formNamesRequiringComment = ['C1-1', 'C1-3', 'C1-3.2', 'C1-4',
        'P6-1', 'P6-1.2', 'P6-2', 'P6-2.2', 'P6-3', 'P6-3.2', 'P6-4', 'P6-4.2',
        'P6-5', 'P6-5.2', 'P6-7', 'P6-8', 'P6-9', 'P6-12', 'P6-14.2', 'P6-23.2', 'P6-16', 'P6-17',
        'P7-1', 'P7-2', 'P7-3', 'P7-4', 'P7-4.1', 'P7-5', 'P7-7', 'P7-8', 'P7-9',
        'P7-10', 'P7-11', 'P7-12', 'P7-13', 'P7-14', 'P7-15', 'P7-18', 'P7-19',
        'P7-20', 'P7-21', 'P7-24', 'P7-28', 'P7-29', 'P7-30', 'P7-31', 'P6-14',
        'P8-1.1', 'P8-2.1', 'P8-3.1', 'P8-4.1', 'P8-5.1', 'P8-6.1', 'P6-18',
        'P8-7.1', 'P8-8.1', 'P8-9.1', 'P8-10.1', 'P8-11.1', 'P8-12.1', 'P6-22',
        'P7-22', 'P8-13.1', 'S7-2', 'S7-3', 'S7-4', 'P6-26', 'P6-27', 'P6-31', 'P7-35', 'P6-28'];
    if (formNamesRequiringComment.indexOf(formName) < 0 || formData.F472SignalToTriggerLevelPriortoTest === 'Error') {
        return { rows: [] };
    }
    return ToleranceInstrumentEUComment(selectedTag, formName, formData);
};
