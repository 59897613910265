import {
    AsFoundTestTableF39HmiInst1Formula,
    AsFoundTestTableF40HmiInst2Formula,
    AsFoundTestTableF41HmiInst3Formula,
    AsFoundTestTableF54DeviationEu1Formula,
    AsFoundTestTableF55DeviationEu2Formula,
    AsFoundTestTableF56DeviationEu3Formula
} from './P711.formulas';
import { paddingAF } from '../../../AsLeftTestTableSection/Data/M71/M71.data';

export const paddingValueTR = '5px 25px 0 0';
export const gaugeLabelTrfs = ['P7-35', 'P6-28'];

export const AsFoundTestTableSectionDataP711 = (formData, formName) => {
    const isGaugeLabel = gaugeLabelTrfs.includes(formName);
    const labelValue = isGaugeLabel ? 'Gauge Reading (EU)' : 'Simulated Input (EU)';
    const keys = isGaugeLabel ? [
        'F124AftGaugeReading1',
        'F125AftGaugeReading2',
        'F126AftGaugeReading3'
    ] : [
        'F665AftSimulatedGap1',
        'AftSimulatedGap2',
        'AftSimulatedGap3'
    ];
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'As-FoundTest',
                    label: 'As-Found Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: 700,
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: '%-HMI-Inst.',
                    label: '% - HMI - Inst.',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    labelWidth: '100%',
                    padding: '5px 0 5px 8px',
                    fontSize: '9pt'
                },
                {
                    key: 'SimulatedInput',
                    label: labelValue,
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'TXReading',
                    label: 'TX Reading (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    textAlign: 'center',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'HMIReading',
                    label: 'HMI Reading (HMI EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'mAReading',
                    label: 'mA Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Deviation',
                    label: 'Deviation (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: 700,
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F39HmiInst1',
                    value: AsFoundTestTableF39HmiInst1Formula(formData).length > 255
                        ? AsFoundTestTableF39HmiInst1Formula(formData).slice(0, 255) : AsFoundTestTableF39HmiInst1Formula(formData),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: keys[0],
                    value: formData[keys[0]],
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F45TxReading1',
                    value: formData.F45TxReading1,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F48HmiReading1',
                    value: formData.F48HmiReading1,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F51MAReading1',
                    value: formData.F51MAReading1,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F54DeviationEu1',
                    value: AsFoundTestTableF54DeviationEu1Formula(formData),
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    readOnly: true,
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F40HmiInst2',
                    value: AsFoundTestTableF40HmiInst2Formula(formData).length > 255
                        ? AsFoundTestTableF40HmiInst2Formula(formData).slice(0, 255) : AsFoundTestTableF40HmiInst2Formula(formData),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: keys[1],
                    value: formData[keys[1]],
                    isRequired: true,
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F46TxReading2',
                    value: formData.F46TxReading2,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F49HmiReading2',
                    value: formData.F49HmiReading2,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F52MAReading2',
                    value: formData.F52MAReading2,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F55DeviationEu2',
                    value: AsFoundTestTableF55DeviationEu2Formula(formData),
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    readOnly: true,
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F41HmiInst3',
                    value: AsFoundTestTableF41HmiInst3Formula(formData).length > 255
                        ? AsFoundTestTableF41HmiInst3Formula(formData).slice(0, 255) : AsFoundTestTableF41HmiInst3Formula(formData),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px'
                },
                {
                    key: keys[2],
                    value: formData[keys[2]],
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F47TxReading3',
                    value: formData.F47TxReading3,
                    isRequired: true,
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F50HmiReading3',
                    value: formData.F50HmiReading3,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F53MAReading3',
                    value: formData.F53MAReading3,
                    isRequired: true,
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F56DeviationEu3',
                    value: AsFoundTestTableF56DeviationEu3Formula(formData),
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    inputMarginRight: '4px',
                    readOnly: true,
                }]
            }
        ]
    };
};
