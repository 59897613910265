import {
    AsLeftTableF78Deviation2Eu1Formula,
    AsLeftTableF79Deviation2Eu2Formula,
    AsLeftTableF80Deviation2Eu3Formula,
    TestResultF81AsLeftResultFormula
} from './P711.formulas';
import {
    AsFoundTestTableF39HmiInst1Formula,
    AsFoundTestTableF40HmiInst2Formula,
    AsFoundTestTableF41HmiInst3Formula,
} from '../../../AsFoundTestTableSection/Data/P711/P711.formulas';
import { disableAsLeftWithSensorTrimVal } from '../../AsLeftTestTableSection.helpers';
import { paddingAF } from '../M71/M71.data';
import { conditionalValueSelector } from 'helpers/Validation';
import { gaugeLabelTrfs } from '../../../AsFoundTestTableSection/Data';

export const AsLeftTestTableSectionDataP711 = (formData, formName) => {
    const labelValue = gaugeLabelTrfs.includes(formName) ? 'Gauge Reading (EU)' : 'Simulated Input (EU)';
    const keys = gaugeLabelTrfs.includes(formName) ? [
        'F139AltGaugeReading1',
        'F140AltGaugeReading2',
        'F141AltGaugeReading3'
    ] : [
        'F701AltSimulatedGap1',
        'AltSimulatedGap2',
        'AltSimulatedGap3'
    ];
    return {
        rows: [
            {
                key: 1,
                fields: [{
                    key: 'AsleftTest',
                    label: 'As-Left Test',
                    isLabel: true,
                    xs: 12,
                    fontWeight: '700',
                    padding: '5px 0 0 8px'
                }]
            },
            {
                key: 2,
                fields: [{
                    key: 'HMIINST',
                    label: '% - HMI - Inst.',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    padding: '5px 0 5px 8px',
                    fontSize: '9pt'
                },
                {
                    key: 'SimulatedInputALT',
                    label: labelValue,
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'TXReadingAlt',
                    label: 'TX Reading (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    textAlign: 'center',
                    labelWidth: '100%',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'HMIReadingAlt',
                    label: 'HMI Reading (HMI EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'mAReading',
                    label: 'mA Reading',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    labelWidth: '100%',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt'
                },
                {
                    key: 'Deviation',
                    label: 'Deviation (EU)',
                    isLabel: true,
                    xs: 2,
                    fontWeight: '700',
                    textAlign: 'center',
                    marginTop: '15px',
                    padding: paddingAF,
                    fontSize: '9pt',
                }]
            },
            {
                key: 3,
                fields: [{
                    key: 'F63Hmi2Inst1',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                        '', AsFoundTestTableF39HmiInst1Formula(formData)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: keys[0],
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                        '', formData[keys[0]]),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F69Tx2Reading1',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData.F69Tx2Reading1),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F72Hmi2Reading1',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData.F72Hmi2Reading1),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F75MA2Reading1',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData.F75MA2Reading1),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F78Deviation2Eu1',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', AsLeftTableF78Deviation2Eu1Formula(formData)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px',
                }]
            },
            {
                key: 4,
                fields: [{
                    key: 'F64Hmi2Inst2',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', AsFoundTestTableF40HmiInst2Formula(formData)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                    marginLeft: '4px'
                },
                {
                    key: keys[1],
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData[keys[1]]),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F70Tx2Reading2',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData.F70Tx2Reading2),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F73Hmi2Reading2',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData.F73Hmi2Reading2),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                    borderBottom: '0'
                },
                {
                    key: 'F76MA2Reading2',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData.F76MA2Reading2),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                },
                {
                    key: 'F79Deviation2Eu2',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', AsLeftTableF79Deviation2Eu2Formula(formData)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderBottom: '0',
                    borderRight: '0',
                    inputMarginRight: '4px'
                }]
            },
            {
                key: 5,
                fields: [{
                    key: 'F65Hmi2Inst3',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', AsFoundTestTableF41HmiInst3Formula(formData)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    readOnly: true,
                    height: '1.2rem',
                    borderRight: '0',
                    borderLeft: '0',
                    marginLeft: '4px'
                },
                {
                    key: keys[2],
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData[keys[2]]),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    maxLength: '10',
                    xs: 2,
                    type: 'number',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F71Tx2Reading3',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData.F71Tx2Reading3),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    xs: 2,
                    type: 'number',
                    maxLength: '10',
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F74Hmi2Reading3',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData.F74Hmi2Reading3),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F77MA2Reading3',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', formData.F77MA2Reading3),
                    isRequired: !(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    type: 'number',
                    maxLength: '10',
                    xs: 2,
                    width: '100%',
                    labelWidth: '0',
                    textAlign: 'center',
                    height: '1.2rem',
                    borderRight: '0',
                },
                {
                    key: 'F80Deviation2Eu3',
                    value: conditionalValueSelector(disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed), '', AsLeftTableF80Deviation2Eu3Formula(formData)),
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    readOnly: true,
                    xs: 2,
                    textAlign: 'center',
                    width: '100%',
                    labelWidth: '0',
                    height: '1.2rem',
                    borderRight: '0',
                    inputMarginRight: '4px',
                }]
            },
            {
                key: 6,
                fields: [{
                    key: 'NoData2',
                    xs: 4,
                    isLabel: true,
                    padding: '5px 25px 18.5px 0'
                },
                {
                    key: 'F81AsLeftResultLabel',
                    value: 'As-Left Result',
                    readOnly: true,
                    xs: 2,
                    isLabelBold: true,
                    textAlign: 'end',
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    borderRight: '0',
                    borderTop: '0',
                    borderLeft: '0',
                    borderBottom: '0',
                },
                {
                    key: 'F81AsLeftResult',
                    value: TestResultF81AsLeftResultFormula(formData),
                    readOnly: true,
                    isCalculatedNew: true,
                    isDisabled: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed),
                    xs: 2,
                    labelWidth: '0',
                    width: '100%',
                    height: '1.1rem',
                    borderTop: disableAsLeftWithSensorTrimVal(formData.F102AftResult, formData.F62SensorTrimNeed) ? '' : '0',
                    inputMarginRight: '-2px',
                    borderBottom: '0',
                    marginBottom: '4px',
                    textAlign: 'center'
                }]
            }
        ]
    };
};
