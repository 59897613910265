export const formatDate = (date) => date
    ? new Intl.DateTimeFormat('en-GB',
        {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: 'numeric',
            hourCycle: 'h12'
        }).format(date).replace(' ', '-').replace(' ', '-')
        .replace(',', '')
        .replace('am', 'AM')
        .replace('pm', 'PM')
        .replace('Sept', 'Sep') : '';

export const formatDateToUTC = (date) => date
    ? new Intl.DateTimeFormat('en-GB',
        {
            month: 'short',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: 'numeric',
            hourCycle: 'h12',
            timeZone: 'UTC'
        }).format(date).replace(' ', '-').replace(' ', '-')
        .replace(',', '')
        .replace('am', 'AM')
        .replace('pm', 'PM')
        .replace('Sept', 'Sep') : '';

export const parseDate = inputDate => {
    // Check if the input date is in the format (DD-MMM-YYYY HH:MM AM/PM)
    const dateTimeFormatRegex = /^\d{2}-[A-Za-z]{3}-\d{4} \d{2}:\d{2} [APM]{2}$/;
    if (dateTimeFormatRegex.test(inputDate)) {
        // Split the input date string to separate the date and time parts
        const [datePart] = inputDate.split(' ');
        return datePart;
    }

    // Check if the input date is already in the required format (DD-MMM-YYYY)
    const requiredFormatRegex = /^\d{2}-[A-Za-z]{3}-\d{4}$/;
    if (requiredFormatRegex.test(inputDate)) {
        return inputDate;
    }

    // Split the input date string to extract year, month, and day
    const extractedDate = inputDate.toString().split('T')[0];
    const [year, month, day] = extractedDate.split('-');

    // Map the month number to the abbreviated month name
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = monthNames[parseInt(month, 10) - 1];
    // Construct the formatted date string
    return `${day}-${monthName}-${year}`;
};

export const getReferenceDate = (currentDate, ref) => new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + ref);
