import { AdditionalInformationData } from './Data/Data';
import { AdditionalInformationDataP76 } from './Data/P76/P76.data';
import { AdditionalInformationDataP81 } from './Data/P81/P81.data';
import { AdditionalInformationDataP7sP6sP8s } from './Data/P7s-P6s-P8s/P7s-P6s-P8s.data';

export const selectAdditionalInformationData = (selectedTag, formName, formData, selectedTrfId) => {
    let filterViewData = AdditionalInformationData(selectedTag, formData, selectedTrfId);
    const formNameList = ['P7-7', 'P7-8', 'P7-30', 'P7-9', 'P7-28', 'P7-29', 'P7-10', 'P7-12', 'P7-13',
        'P7-14', 'P7-18', 'P7-16', 'P7-17', 'M6-16', 'P7-19', 'P7-23', 'P7-15', 'P6-1',
        'P6-1.2', 'P6-2', 'P6-2.2', 'P6-3', 'P6-3.2', 'P6-4', 'P6-4.2', 'P6-5', 'P6-5.2',
        'P6-6', 'P6-7', 'P6-12', 'P6-9', 'P6-10', 'P6-17', 'P6-20', 'C1-2', 'P6-8',
        'P8-1.1', 'P8-2.1', 'P8-3.1', 'P8-4.1', 'P8-10.1', 'P8-11.1', 'P8-6.1', 'P8-7.1',
        'P8-8.1', 'P8-9.1', 'M6-3', 'M6-4', 'P6-11', 'M6-5', 'M6-6', 'M6-7', 'M6-9',
        'M3-1', 'M6-8', 'M6-10', 'M6-12', 'M6-11', 'M6-13', 'M6-14', 'M6-15', 'P6-14.2', 'P6-14', 'P6-23.2',
        'P7-24', 'P7-25', 'P7-26', 'P7-27', 'P7-31', 'P6-15', 'P6-16', 'P7-32', 'P7-33',
        'M7-1', 'P7-20', 'P7-21', 'P8-12.1', 'P7-11', 'P8-13.1', 'P8-14.1', 'P8-17.1', 'P6-13', 'P6-18', 'P6-22', 'P7-22', 'S7-1', 'S7-2', 'S7-3',
        'S7-4', 'M4-2', 'P6-26', 'P6-29', 'P6-27', 'P6-25', 'P6-31', 'P6-30', 'P7-35', 'P6-28'];
    if (formName && (formName === 'P7-6')) {
        filterViewData = AdditionalInformationDataP76(selectedTag, formData, selectedTrfId);
    } else if (formName && formNameList.includes(formName)) {
        filterViewData = AdditionalInformationDataP7sP6sP8s(selectedTag, formData, selectedTrfId);
    } else if (formName === 'P8-1') {
        filterViewData = AdditionalInformationDataP81(selectedTag, formData, selectedTrfId);
    }
    return filterViewData;
};
