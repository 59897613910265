import { levelChangeCheckSection } from './Data/LevelChangeCheck.data';
import { FlowChangeCheckSectionP6022 } from './Data/P6022.data';

export const selectFlowChangeSectionData = (selectedTag, formName, formData) => {
    let filterViewData = { rows: [] };
    if ((['P6-22', 'P7-22', 'P7-15', 'P7-14', 'P7-18', 'P6-12', 'P7-29', 'P8-8.1', 'P8-12.1', 'P7-30', 'P6-27', 'P6-31']).includes(formName)) {
        filterViewData = FlowChangeCheckSectionP6022(formData, formName);
    } else if (['P6-4', 'P6-4.2', 'P6-5', 'P6-5.2', 'P6-17', 'P7-7', 'P7-8', 'P7-11', 'P7-12', 'P7-28', 'P8-3.1', 'P8-13.1', 'P7-35', 'P6-28'].includes(formName)) {
        filterViewData = levelChangeCheckSection(formData, formName);
    }
    return filterViewData;
};
